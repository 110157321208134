export default {
  tracking: 'tracking',
  // dashboard: 'dashboard',
  forgotPassword: 'forgotPassword',
  faq: 'faq',
  reporting: 'reporting',
  insights: 'insights',
  alerts: 'alerts',
  detailreport: 'detailreport',
  absTroubleshooting: 'absTroubleshooting',
  specs: 'specs',
  unsubscribe: 'unsubscribe',
  parts: 'parts',
  userManagement: 'user-management',
  trailerManagement: 'trailer-management',
  subscriptionManagement: 'subscription-management',
  groupManagement: 'group-management',
  customerManagement: 'customer-management',
  fleet: 'currentFleetStatus',
  otaManagement: 'ota-management',
  otaFtpManagement: 'ota-ftp-management',
  freight: 'freight',
  freightLink: 'freight-link',
  vinTransfer: 'vin-transfer',
  leaseManagement: 'lease-management',
  profile: 'profile',
};

export const PUBLIC_ROUTES = {
  EMAIL_VERIFICATION: {
    key: 'EMAIL_VERIFICATION',
    route: 'emailverification',
  },
  FORGOT_PASSWORD: {
    key: 'FORGOT_PASSWORD',
    route: 'forgotPassword',
  },
  RESET_PASSWORD: {
    key: 'RESET_PASSWORD',
    route: 'resetPassword',
  },
  UNSUBSCRIBE: {
    key: 'UNSUBSCRIBE',
    route: 'unsubscribe',
  },
  SET_PASSWORD: {
    key: 'SET_PASSWORD',
    route: 'setpassword',
  },
  CREATE_TECHNICIAN_ACCOUNT: {
    key: 'CREATE_TECHNICIAN_ACCOUNT',
    route: 'createTechnicianAccount',
  },
};
