export const fetchMeQuery = `{
  me {
    id
    email
    isLoggedIn
    customerNames
    timezone {
      id
      country
      description
      utcDaylightOffset
    }
  }
}`;
