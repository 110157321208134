import React from 'react';
import { useHistory } from 'react-router-dom';
import './SettingsNavigationTabs.css';
import pageNames from '../../constants/enums/pageNames';
import { isTabActive } from '../../utils/navigationUtils';
import Tab from '../Tab/Tab';
import { isAdminOrCustomerAdmin, isUserAdmin, isUserDealer, isUserSupport } from '../../../src/security/authorization';
import { useSelector } from 'react-redux';

const SettingsNavigationTabs = () => {
  let history = useHistory();
  const { OtaManagement, VinTransferPortal, SubscriptionManagement } = useSelector(state => state.featureFlag?.features);
  const {
    profile,
    userManagement,
    trailerManagement,
    groupManagement,
    customerManagement,
    otaManagement,
    otaFtpManagement,
    vinTransfer,
    subscriptionManagement,
    leaseManagement,
  } = pageNames;
  const isProfileTabActive = isTabActive(profile);
  const isUserTabActive = isTabActive(userManagement);
  const isTrailerTabActive = isTabActive(trailerManagement);
  const isSubscriptionTabActive = isTabActive(subscriptionManagement);
  const isGroupTabActive = isTabActive(groupManagement);
  const isCustomerTabActive = isTabActive(customerManagement);
  const isVinTransferActive = isTabActive(vinTransfer);
  const isOtaTabActive = isTabActive(otaManagement);
  const istOtaFtpTabActive = isTabActive(otaFtpManagement);
  const isLeaseManagementTabActive = isTabActive(leaseManagement);

  const isThereAnActiveTab =
    isProfileTabActive ||
    isUserTabActive ||
    isTrailerTabActive ||
    isSubscriptionTabActive ||
    isGroupTabActive ||
    isCustomerTabActive ||
    isVinTransferActive ||
    isOtaTabActive ||
    isOtaTabActive ||
    istOtaFtpTabActive ||
    isLeaseManagementTabActive;

  const userTabAccess = {
    userManagement: isAdminOrCustomerAdmin(),
    trailerManagement: isAdminOrCustomerAdmin(),
    subscriptionManagement: isAdminOrCustomerAdmin() && SubscriptionManagement,
    groupManagement: isAdminOrCustomerAdmin(),
    customerManagement: isUserAdmin(),
    otaManagement: isUserAdmin() && OtaManagement,
    otaFtpManagement: isUserAdmin() && OtaManagement,
    vinTransfer: (isUserAdmin() || isUserSupport() || isUserDealer()) && VinTransferPortal,
    leaseManagement: isUserAdmin(),
  };

  return (
    <>
      <div className="userManagement-navigation">
        <Tab tabLabel="Profile" onClick={() => history.push('/profile')} isActive={isProfileTabActive} />
        {userTabAccess.userManagement && <Tab tabLabel="User Management" onClick={() => history.push('/user-management')} isActive={isUserTabActive} />}
        {userTabAccess.trailerManagement && (
          <Tab tabLabel="Trailer Management" onClick={() => history.push('/trailer-management')} isActive={isTrailerTabActive} />
        )}
        {userTabAccess.subscriptionManagement && (
          <Tab tabLabel="Subscriptions" onClick={() => history.push('/subscription-management')} isActive={isSubscriptionTabActive} />
        )}
        {userTabAccess.groupManagement && <Tab tabLabel="Group Management" onClick={() => history.push('/group-management')} isActive={isGroupTabActive} />}
        {userTabAccess.customerManagement && (
          <Tab tabLabel="Customer Management" onClick={() => history.push('/customer-management')} isActive={isCustomerTabActive} />
        )}
        {userTabAccess.otaManagement && <Tab tabLabel="OTA Management" onClick={() => history.push('/ota-management')} isActive={isOtaTabActive} />}
        {userTabAccess.otaFtpManagement && (
          <Tab tabLabel="OTA FTP Management" onClick={() => history.push('/ota-ftp-management')} isActive={istOtaFtpTabActive} />
        )}
        {userTabAccess.vinTransfer && <Tab tabLabel="VIN Transfer" onClick={() => history.push('/vin-transfer')} isActive={isVinTransferActive} />}
        {userTabAccess.leaseManagement && (
          <Tab tabLabel="Lease Management" onClick={() => history.push('/lease-management')} isActive={isLeaseManagementTabActive} />
        )}
      </div>
      {!isThereAnActiveTab && (
        <div className="settings-text">
          {Object.values(userTabAccess).some(Boolean)
            ? 'Select a tab from the navigation above.'
            : 'You don’t have access to any setting management tabs at this time.'}
        </div>
      )}
    </>
  );
};

export default SettingsNavigationTabs;
